/* You can add global styles to this file, and also import other style files */
@import "styles/variables";

@import "@fd/core/styles";

@import "styles/angular-material/overwrites";

@import "styles/utilities";
@import "styles/components";

@import "../node_modules/svgmap/dist/svgMap.css";
