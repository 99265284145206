@use "@angular/material" as mat;

.mat-dense-1 {
  @include mat.all-component-densities(-1);
}

// Make scrollable area of dialogs more distinct
mat-dialog-content {
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.12);
  border-width: 1px 0;
}

// Use normal text color for dialog content instead of rgba(0, 0, 0, 0.6);
.mat-mdc-dialog-container {
  --mdc-dialog-supporting-text-color: rgba(0, 0, 0, 0.87);
}

//
// Small Icons
//

.small-icons mat-icon {
  font-size: 1em;
  height: 1em;
  width: 1em;
}

//
// Lights
//

[data-light] {
  display: inline-block;
  font-size: 1ch; // initial relative size of approximately one character at the current font size
  width: 1.25em;
  height: 1.25em;
  line-height: inherit;
  vertical-align: top;
  transform: translateY(55%);
  background-color: currentColor;
  border-radius: 100px;
  margin: 0 0 0.25em;
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
}

[data-light="warning"] {
  color: #ffc107;
}

[data-light="success"] {
  color: #198754;
}

[data-light="danger"] {
  color: #dc3545;
}

//
// Page Header
//

.page-header {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: calc(var(--gutter) * 1.5) 0 calc(var(--gutter) * 1.5) 0;
  align-items: center;

  @media (min-width: 600px) {
    grid-template-columns: 1fr minmax(min-content, 2fr) 1fr;
  }
}

//
// Data Grid
//

dl.labeled-list {
  display: grid;
  grid-template-columns: fit-content(50%) fit-content(50%);
  column-gap: var(--gutter);
  margin: 0;

  dt,
  dd {
    margin: 0;
  }

  &.constrain-small {
    dt,
    dd {
      min-width: 90px; // helps keep items visually aligned across cards. Based on the approximate size of a medium date.
    }
  }

  dt {
    font-weight: normal;
    text-align: right;
  }

  dd {
    font-weight: bold;
    white-space: nowrap;
  }

  > div {
    grid-column: span 2;
  }
}

.mat-mdc-form-field {
  // default is 180px which is often too wide
  &.is-sm .mat-mdc-form-field-infix {
    width: 90px; // fits a datepicker nicely
  }

  &.is-md .mat-mdc-form-field-infix {
    width: 140px;
  }
}
