//
// Utilities
//

// Bootstrap doesn't offer these

.justify-self-center {
  justify-self: center;
}
@include media-breakpoint-up(sm) {
  .justify-self-sm-center {
    justify-self: center;
  }
}
@include media-breakpoint-up(md) {
  .justify-self-md-center {
    justify-self: center;
  }
}
@include media-breakpoint-up(lg) {
  .justify-self-lg-center {
    justify-self: center;
  }
}
@include media-breakpoint-up(xl) {
  .justify-self-xl-center {
    justify-self: center;
  }
}

.justify-self-end {
  justify-self: end;
}
@include media-breakpoint-up(sm) {
  .justify-self-sm-end {
    justify-self: end;
  }
}
@include media-breakpoint-up(md) {
  .justify-self-md-end {
    justify-self: end;
  }
}
@include media-breakpoint-up(lg) {
  .justify-self-lg-end {
    justify-self: end;
  }
}
@include media-breakpoint-up(xl) {
  .justify-self-xl-end {
    justify-self: end;
  }
}

.overflow-ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  min-width: 0;
  width: 100%;
  overflow: hidden;
}
